import useTranslation from "next-translate/useTranslation";
import { Flex, Box, Text } from "rebass/styled-components";
import Heading from "../common/Heading";
import Icon from "../common/Icon";

interface FeatureProps {
  icon?: string;
  prefix?: number;
  title: string;
  description?: string;
  descriptionList?: string[];
  color?: string;
}
export const Feature = ({
  icon,
  prefix,
  title,
  description,
  descriptionList,
  color = "inuOrange"
}: FeatureProps) => {
  return (
    <Flex pb={[4, 4, 0]} px={2} width={[1, 1 / 3]} flexWrap="wrap">
      <Flex alignItems={!!descriptionList && "center"}>
        <Box
          mr={2}
          textAlign="center"
          fontSize={7}
          sx={{ alignSelf: "flex-start" }}
        >
          {!!icon && <Icon name={icon} color={color} />}
          {!!prefix && (
            <Text
              fontSize={5}
              color={color}
              sx={{
                border: "2px solid",
                borderColor: color,
                borderRadius: "circle",
                width: 48,
                height: 48,
                lineHeight: "46px"
              }}
            >
              {prefix}
            </Text>
          )}
        </Box>
        <Box flex={1}>
          <Heading
            as="h3"
            sx={{
              fontSize: 3,
              fontWeight: "book",
              lineHeight: "32px"
            }}
          >
            {title}
          </Heading>
          {description && <Text as="p">{description}</Text>}
          {!!descriptionList && (
            <Text as="ul" p={0} width={1}>
              {descriptionList.map((description, index) => (
                <Text
                  key={index}
                  as="li"
                  sx={{
                    flex: 1,
                    fontSize: 2,
                    listStyle: "none",
                    lineHeight: 1.5
                  }}
                >
                  <Flex alignItems="flex-start">
                    <Box mr={1}>
                      <Icon name="check" color="springGreen" fontSize={0} />
                    </Box>
                    {description}
                  </Flex>
                </Text>
              ))}
            </Text>
          )}
        </Box>
      </Flex>
    </Flex>
  );
};

const Steps = ({ color }: { color?: string }) => {
  const { t } = useTranslation("home");
  return (
    <Box variant="layout.semilarge" py={[5, 5, 7]}>
      <Heading as="h2" variant="h2" pb={3}>
        {t("howGudogWorks")}
      </Heading>
      <Flex flexWrap="wrap" justifyContent="space-between" mx={-2}>
        <Feature
          prefix={1}
          title={t("stepOne")}
          description={t("stepOneDesc")}
          color={color}
        />
        <Feature
          prefix={2}
          title={t("stepTwo")}
          description={t("stepTwoDesc")}
          color={color}
        />
        <Feature
          prefix={3}
          title={t("stepThree")}
          description={t("stepThreeDesc")}
          color={color}
        />
      </Flex>
    </Box>
  );
};

export default Steps;
