import React, { useContext } from "react";
import { Flex, Box } from "rebass/styled-components";
import { Link as RebassLink } from "rebass/styled-components";
import Image from "next/image";
import useTranslation from "next-translate/useTranslation";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import Heading from "../common/Heading";
import Button from "../common/Button";
import { blogStyles as styles } from "../../lib/styles/homeStyles";
import { LocaleContext } from "./../locale";
import { BlogPost } from "../../generated/graphql";

const BLOG_QUERY = gql`
  query getBlog {
    blog(page: 1, perPage: 3) {
      url
      posts {
        link
        excerpt
        title
        featuredMedia
      }
    }
  }
`;

const FeaturedMedia = ({ alt, src }: { alt: string; src: string }) => {
  return (
    <Box sx={styles.img}>
      <Image
        src={src}
        alt={alt}
        title={alt}
        fill
        sizes="100vw"
        style={{
          objectFit: "cover"
        }} />
    </Box>
  );
};

export function BlogPosts({ posts }: { posts: [BlogPost] }) {
  const { t } = useTranslation("common");

  return (
    <Flex mx={-2} flexDirection={["column", "row"]}>
      {posts?.map((post, index) => {
        return (
          <Box key={index} sx={styles.wrapper}>
            <a
              href={post.link}
              target="_blank"
              rel="noopener noreferrer"
              title={post.title}
            >
              <FeaturedMedia alt={post.title} src={post.featuredMedia!} />
            </a>

            <Heading as="h3" variant="highlight">
              <RebassLink
                href={post.link}
                color="labradorBlack"
                target="_blank"
                rel="noopener noreferrer"
                title={post.title}
              >
                {post.title}
              </RebassLink>
            </Heading>

            <Box
              dangerouslySetInnerHTML={{
                __html: post.excerpt?.trim().replace(/(\r\n|\n|\r)/gm, "")
              }}
            ></Box>

            <Box sx={{ color: "springGreen" }}>
              <RebassLink
                variant="secondaryLink"
                href={post.link}
                target="_blank"
                sx={styles.link}
              >
                {t("readMore")}
              </RebassLink>
            </Box>
          </Box>
        );
      })}
    </Flex>
  );
}

export default function Blog() {
  const { t } = useTranslation("home");
  const { locale } = useContext(LocaleContext);

  const { data } = useQuery(BLOG_QUERY);

  if (locale == "de" || !data?.blog?.posts?.length) {
    return null;
  }

  return (
    <>
      <Flex variant="layout.semilarge" sx={styles.container}>
        <Box width={1}>
          <Heading as="h2" variant="h2">
            {t("gudogBlog")}
          </Heading>
        </Box>

        <BlogPosts posts={data?.blog?.posts} />

        <Box width={[1, 400, 300]} pt={4} mx={"auto"}>
          {data?.blog && (
            <Button width={1} variant="terciary">
              <Box
                as="a"
                href={data.blog.url}
                target="blank"
                sx={styles.button}
              >
                {t("seeAllPosts")}
              </Box>
            </Button>
          )}
        </Box>
      </Flex>
    </>
  );
}
