import theme from "../theme";

export const searchStyles = {
  bannerFlex: {
    flexDirection: "column",
    flexGrow: 1,
    width: "inherit",
    my: ["10px", 0, 0]
  },
  searchBox: {
    flexDirection: "column",
    gap: "16px",
    mx: [2, "4.33333vw", "4.33333vw"],
    background: "white",
    border: ["none", "1px solid"],
    borderColor: ["inherit", "gray"],
    borderRadius: [0, "4px", "4px"],
    boxShadow: ["0", "inputFocus", "inputFocus"],
    p: [2, 4, 4],
    py: 3,
    mt: [0, 6, 6]
  },
  searchBanner: {
    mx: [2, "4.33333vw", "4.33333vw"],
    borderRadius: "0 0 4px 4px",
    boxShadow: ["0", "inputFocus", "inputFocus"],
    backgroundColor: "pugYellow",
    px: 4,
    py: 3,
    mb: "10px",
    color: "white",
    fontSize: "20px",
    lineHeight: "25px",
    fontWeight: "body"
  },
  searchBoxWithBanner: {
    mb: ["20px", "20px", 0, 0],
    borderRadius: "4px 4px 0 0"
  },
  annotation: {
    mt: "4px",
    fontWeight: 500
  },
  subTitle: {
    mb: 1,
    fontSize: "16px",
    lineHeight: "20px"
  },
  servicesContainer: {
    flexDirection: ["column", "column", "row"],
    gap: "4px",
    justifyContent: "space-between",
    textAlign: "center"
  },
  serviceBlock: {
    flex: 1,
    alignItems: "center",
    cursor: "pointer",
    gap: ["12px", "12px", "8px"],
    p: "12px",
    border: "1px solid",
    borderColor: "lightGray",
    borderRadius: "6px"
  },
  serviceBlockMobile: {
    paddingTop: "8px",
    paddingBottom: "8px"
  },
  serviceBlockWeb: {
    flexDirection: "column"
  },
  serviceBlockSelected: {
    borderColor: theme.colors.springGreen,
    backgroundColor: theme.colors.springGreen
  },
  serviceIconView: {
    justifyContent: "center",
    alignItems: "center",
    width: "32px",
    height: "32px"
  },
  textBlock: {
    flex: 1,
    flexDirection: "column",
    alignItems: ["flex-start", "flex-start", "center"]
  },
  serviceTitle: {
    color: theme.colors.darkGray,
    fontWeight: 600,
    marginBottom: 1
  },
  serviceDesc: {
    color: theme.colors.darkGray
  },
  reviewsblock: {
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  }
};

export const featuredInStyles = {
  mainBox: {
    pt: "2",
    pb: "1",
    backgroundColor: "#eee",
    color: "veryDarkGray",
    overflow: "hidden"
  },
  heading: {},
  flex: {
    alignItems: "center",
    flexWrap: "nowrap"
  },
  boxImg: {
    flexShrink: [0, 0, 1],
    width: ["50%", "100%"],
    px: "2",
    py: "2",
    textAlign: "center",
    verticalAlign: "center",
    "> span": {
      position: "unset !important",
      maxHeight: "32px"
    },
    ".featured-in-image": {
      "object-fit": "contain",
      width: "100% !important",
      position: "relative !important",
      height: "unset !important"
    },
    img: {
      maxHeight: "32px !important"
    }
  }
};

export const benefitsStyles = {
  containerIcon: {
    width: "80px",
    height: "80px",
    justifyContent: "center",
    alignItems: "center",
    gap: "32px",
    border: "1px solid",
    borderColor: "springGreen",
    borderRadius: "24px"
  },
  mainContainer: {
    flexDirection: ["column", "column", "row"],
    gap: "32px"
  },
  containerIconText: {
    flex: 1,
    flexDirection: ["row", "row", "column"],
    gap: "12px",
    alignItems: "center"
  },
  text: {
    flex: [1, 1, "inherit"],
    fontWeight: 400,
    mr: [1, 1, 0],
    textAlign: ["left", "left", "center"]
  }
};

export const mobileAppStyles = {
  mainContainer: {
    flexDirection: ["column", "column", "row"],
    gap: ["16px", "16px", "32px"],
    bg: "inuOrange",
    py: "40px"
  },
  mobileImgContainer: {
    width: ["80%", "80%", "37%"],
    justifyContent: "flex-start",
    alignItems: "center",
    m: "auto"
  },
  img: {
    ml: [-1, -1, "-30px"],
    maxWidth: "100%",
    maxHeight: "100%",
    width: "auto",
    height: "auto"
  },
  infoContainer: {
    flexDirection: "column",
    gap: 3,
    color: "samoyedWhite"
  },
  mainTitle: {
    fontSize: "32px",
    pr: "80px"
  },
  iconContainer: {
    width: "80px",
    height: "80px",
    borderRadius: "24px",
    bg: "samoyedWhite",
    justifyContent: "center",
    alignItems: "center"
  },
  textContainer: {
    flexDirection: "column",
    gap: "4px",
    flex: 1
  },
  downloadContainer: {
    flexDirection: "column",
    gap: "8px",
    justifyContent: ["center", "flex-start"],
    alignItems: ["center", "center", "flex-start"],
    mt: [0, 0, 1]
  }
};

export const reviewsStyles = {
  title: {
    fontSize: "32px",
    lineHeight: "32px",
    color: "samoyedWhite"
  },
  reviewsContainer: {
    mt: 3,
    mb: 4,
    flexDirection: ["column", "column", "row"],
    gap: "24px",
    justifyContent: "center",
    alignItems: ["center", "center", "stretch"]
  },
  button: {
    width: ["100%", "100%", "384px"],
    height: "48px"
  }
};

export const blogStyles = {
  container: {
    flexWrap: "wrap",
    pt: 3,
    pb: [5, 5, 7],
    width: "100%",
    flexDirection: "column",
    borderBottom: "default"
  },
  wrapper: {
    width: ["100%", "33%", "33%"],
    py: 3,
    px: 2,
    position: "relative",
    color: "veryDarkGray"
  },
  link: {
    bottom: 0,
    position: "absolute",
    fontSize: 3,
    fontWeight: "book"
  },
  button: {
    color: "labradorBlack",
    textDecoration: "none"
  },
  img: {
    mb: 2,
    width: "100%",
    height: "200px",
    position: "relative",
    img: {
      borderRadius: "double"
    }
  }
};
