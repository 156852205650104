import { Flex } from "rebass/styled-components";
import Icon from "./Icon";

function ReviewStars({ sx }: { sx?: any }) {
  return (
    <Flex variant="ratingStar" justifyContent="center" sx={sx}>
      <Icon name="review_star" color="pugYellow" width="25px" height="25px" />
      <Icon name="review_star" color="pugYellow" width="25px" height="25px" />
      <Icon name="review_star" color="pugYellow" width="25px" height="25px" />
      <Icon name="review_star" color="pugYellow" width="25px" height="25px" />
      <Icon name="review_star" color="pugYellow" width="25px" height="25px" />
    </Flex>
  );
}

export default ReviewStars;
