import { Flex, Text, Box } from "rebass/styled-components";
import { numbersGridStyles as styles } from "../../lib/styles/gridStyles";

interface Props {
  title: string;
  items: { title: string; desc: string }[];
  numCols: number;
  variant?: string;
}

export interface NumbersTileProps {
  title: string;
  desc: string;
  width?: string;
  variant?: string;
}

const NumbersTile = (props: NumbersTileProps) => {
  const { title, desc, width, variant } = props;
  const style = styles[variant as keyof typeof styles];

  return (
    <Flex sx={{ ...style.tile, width: width }}>
      <Box sx={style.tileBox}>
        <Text as="h2" text="h2" sx={style.tileTitle}>
          {title}
        </Text>
        <Text as="h3" text="h3" sx={style.tileDesc}>
          {desc}
        </Text>
      </Box>
    </Flex>
  );
};

function NumbersGrid(props: Props) {
  const { title, items, numCols, variant = "default" } = props;
  const style = styles[variant as keyof typeof styles];

  return (
    <Box sx={style.mainBox}>
      <Text as="h2" text="h2" sx={style.title}>
        {title}
      </Text>
      <Flex sx={style.grid}>
        {items.map(item => (
          <NumbersTile
            title={item.title}
            desc={item.desc}
            width={`${100 / numCols}%`}
            variant={variant}
          />
        ))}
      </Flex>
    </Box>
  );
}

export default NumbersGrid;
