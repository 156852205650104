import { Flex, Box, Text } from "rebass/styled-components";
import Heading from "../common/Heading";
import Icon from "../common/Icon";
import useTranslation from "next-translate/useTranslation";
import { benefitsStyles as styles } from "../../lib/styles/homeStyles";

function Benefits() {
  const { t } = useTranslation("home");

  const benefitsIcons = [
    "verified",
    "vet",
    "cancellation",
    "support",
    "secure"
  ];
  const benefitslist = [...Array(benefitsIcons.length)].map((_, i) => {
    return {
      iconName: benefitsIcons[i],
      desc: { text: t(`common:paymentsDesc${i + 1}`), color: "labradorBlack" }
    };
  });

  return (
    <Box variant="layout.semilarge" mb={"40px"}>
      <Heading as="h2" variant="h2" pb={3}>
        {t("common:paymentsTitle")}
      </Heading>
      <Flex sx={styles.mainContainer}>
        {benefitslist.map((benefit, index) => (
          <Flex key={index} sx={styles.containerIconText}>
            <Flex sx={styles.containerIcon}>
              <Icon name={benefit.iconName} color="springGreen" fontSize={6} />
            </Flex>

            <Text as="h4" sx={{ ...styles.text, color: benefit.desc.color }}>
              {benefit.desc.text}
            </Text>
          </Flex>
        ))}
      </Flex>
    </Box>
  );
}

export default Benefits;
