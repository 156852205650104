import { useEffect, useRef, useState, useContext } from "react";
import Head from "next/head";
import { Link as RebassLink } from "rebass/styled-components";
import useTranslation from "next-translate/useTranslation";
import { Box, Text } from "rebass/styled-components";
import Heading from "../common/Heading";
import styled from "styled-components";
import { LocaleContext } from "../locale";

const FaqContainer = styled.div`
  height: ${({ theme }) => theme.fontSizes[2] * theme.lineHeights.body * 2}px;
  overflow: hidden;
  position: relative;
  &.expanded {
    height: auto;
    overflow: auto;
  }
`;

interface ItemProps {
  title: string;
  content: string;
}

const CollapsableListItem = ({ title, content }: ItemProps) => {
  const { t } = useTranslation("home");
  const [expanded, setExpanded] = useState<boolean>();

  const containerRef = useRef<HTMLElement>();
  const contentRef = useRef<HTMLElement>();

  useEffect(() => {
    if (containerRef.current && contentRef.current) {
      const containerBox = containerRef.current.getBoundingClientRect();
      const contentBox = contentRef.current.getBoundingClientRect();

      if (contentBox.bottom > containerBox.bottom) {
        setExpanded(false);
      }
    }
  }, [containerRef, contentRef]);

  return (
    <Text as="li">
      <Text as="h3" fontWeight="book" mr={2} mb={"12px"}>
        {title}
      </Text>

      <FaqContainer ref={containerRef} className={expanded && "expanded"}>
        <Text ref={contentRef} as="p">
          {content}
        </Text>
        {expanded === false && (
          <Text
            pr={1}
            pl={6}
            onClick={() => setExpanded(true)}
            sx={{
              position: "absolute",
              bottom: "4px",
              right: 0,
              background:
                "linear-gradient(to right, transparent 10%, #fff 30%);",
              cursor: "pointer"
            }}
          >
            <Text as="span" color="springGreen">
              {t("seeMore")}
            </Text>
          </Text>
        )}
      </FaqContainer>
    </Text>
  );
};

const Faq = () => {
  const { t } = useTranslation("home");
  const { country } = useContext(LocaleContext);

  const faqs = [
    { question: t("isGudogRight"), answer: t("isGudogRightContent") },
    { question: t("gudogSittersSafe"), answer: t("gudogSittersSafeContent") },
    { question: t("canMeetBefore"), answer: t("canMeetBeforeContent") },
    { question: t("howDoIPay"), answer: t("howDoIPayContent") },
    { question: t("canICancel"), answer: t("canICancelContent") }
  ];

  return (
    <>
      <Head>
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: `{
              "@context": "https://schema.org",
              "@type": "FAQPage",
              "mainEntity": ${JSON.stringify(
                faqs.map(faq => ({
                  "@type": "Question",
                  name: faq.question,
                  acceptedAnswer: {
                    "@type": "Answer",
                    text: faq.answer
                  }
                }))
              )}
            }`
          }}
        />
      </Head>
      <Box variant="layout.semilarge" py={5}>
        <Heading
          as="h2"
          variant="h2"
          pb={3}
          textAlign={["left", "left", "center"]}
        >
          {t("faq")}
        </Heading>
        <Box
          as="ul"
          pl={2}
          sx={{ whiteSpace: "pre-line", li: { marginBottom: 4 } }}
        >
          {faqs.map(faq => (
            <CollapsableListItem title={faq.question} content={faq.answer} />
          ))}
        </Box>

        <RebassLink
          variant="secondaryLink"
          py={1}
          href={process.env.help[country]}
          style={{
            textDecoration: "none"
          }}
          rel="nofollow"
          target="_blank"
        >
          {t("seeHelpCentre")}
        </RebassLink>
      </Box>
    </>
  );
};

export default Faq;
