import React, { createContext, useReducer, useContext } from "react";
import { parseISO } from "date-fns";
import {
  datesStringToDatesArray,
  dateToIso8601,
  parseDogIdsParam,
  isMultipleDates,
  isDateRange
} from "../../lib/utils";
import { MapCoordinates } from "./SearchMap";
import { DogsFilterValue } from "./DogsFilter";
import { GeneralFiltersValue } from "./GeneralFilters";
import { SortByValue } from "./SortBy";
import {
  CountryServiceType,
  QueryType,
  DatesFilterValue,
  Place
} from "../../interfaces";

type Action =
  | {
      type: "changeCountryServiceType";
      payload: {
        countryServiceType: CountryServiceType;
      };
    }
  | {
      type: "changePlace";
      payload: { place: Place };
    }
  | {
      type: "changeCoordinates";
      payload: { coordinates: MapCoordinates };
    }
  | {
      type: "changeDatesFilterValue";
      payload: {
        datesFilterValue: DatesFilterValue;
      };
    }
  | {
      type: "changeDogsFilterValue";
      payload: { dogsFilterValue: DogsFilterValue };
    }
  | {
      type: "changeGeneralFiltersValue";
      payload: { generalFiltersValue: GeneralFiltersValue };
    }
  | {
      type: "clearCountryServiceType";
    }
  | {
      type: "clearPlace";
    }
  | {
      type: "clearDates";
    }
  | {
      type: "reset";
    }
  | {
      type: "changeSortBy";
      payload: { sortBy: SortByValue };
    };

type Dispatch = (action: Action) => void;

interface State {
  countryServiceType: CountryServiceType;
  datesFilterValue: DatesFilterValue;
  dogsFilterValue: DogsFilterValue;
  generalFiltersValue: GeneralFiltersValue;
  coordinates: MapCoordinates;
  place: any;
  page: number;
  sortBy: SortByValue;
}
interface SearchProviderProps {
  children: React.ReactNode;
  initialStateFromQuery: State;
}

const intialSearchState = {
  countryServiceType: undefined,
  datesFilterValue: undefined,
  dogsFilterValue: {
    dogs: [],
    dogIds: []
  },
  generalFiltersValue: undefined,
  coordinates: undefined,
  place: undefined,
  page: undefined,
  sortBy: "scoreDesc"
};

const SearchStateContext = createContext<State | undefined>(undefined);
const SearchDispatchContext = createContext<Dispatch | undefined>(undefined);

function getInitialStateFromQuery(
  query: QueryType,
  countryServiceTypes: CountryServiceType[]
): State {
  let datesFilterValue: DatesFilterValue;
  let dogsFilterValue: DogsFilterValue;
  let generalFiltersValue: GeneralFiltersValue;
  let countryServiceType: CountryServiceType;
  let place: Place;
  let sortBy: SortByValue;

  if (query.serviceDates) {
    datesFilterValue = {
      kind: "multipleDates",
      dates: datesStringToDatesArray(query.serviceDates)
    };
  } else if (query.startDate && query.endDate) {
    datesFilterValue = {
      kind: "dateRange",
      startDate: parseISO(query.startDate),
      endDate: parseISO(query.endDate)
    };
  }

  if (query.serviceTypeId) {
    countryServiceType = countryServiceTypes.find(
      cst => cst.serviceType.id === query.serviceTypeId
    );
  }

  let dogs = [];
  let dogIds = [];

  if (query.dogs) {
    dogs = query.dogs.map(dog => {
      return {
        size: dog.size,
        gender: dog.gender,
        puppy: dog.puppy
      };
    });
  }

  if (query.dogIds) {
    dogIds = parseDogIdsParam(query.dogIds);
  }

  dogsFilterValue = {
    dogs: dogs,
    dogIds: dogIds
  };

  ["withDogs", "withOutsideArea", "withoutKids"].map(key => {
    if (query[key] !== undefined) {
      generalFiltersValue = {
        ...generalFiltersValue,
        [key]: query[key]
      };
    }
  });

  if (query.pid && query.near && query.lat && query.lng) {
    place = {
      pid: query.pid,
      near: query.near,
      lat: query.lat,
      lng: query.lng
    };
  }

  if (query.sortBy) {
    sortBy = query.sortBy;
  }

  const { page, coordinates } = query;

  return {
    countryServiceType,
    place,
    page,
    coordinates,
    datesFilterValue,
    dogsFilterValue,
    generalFiltersValue,
    sortBy
  };
}

function datesFilterValueToQueryParams(value: DatesFilterValue) {
  if (isMultipleDates(value)) {
    return {
      serviceDates: value.dates.map(date => dateToIso8601(date)).join("/")
    };
  } else if (isDateRange(value)) {
    return {
      startDate: dateToIso8601(value.startDate),
      endDate: dateToIso8601(value.endDate)
    };
  }
}

function dogsFilterValueToQueryParams(value: DogsFilterValue) {
  let dogs = undefined;
  let dogIds = undefined;

  if (value) {
    if (value.dogs && value.dogs.length > 0) {
      dogs = JSON.stringify(value.dogs);
    }

    if (value.dogIds && value.dogIds.length > 0) {
      dogIds = value.dogIds;
    }
  }
  return { dogs, dogIds };
}

function stateToQueryParams(state: State) {
  const queryParams = {
    ...state.place,
    ...datesFilterValueToQueryParams(state.datesFilterValue),
    ...state.coordinates,
    serviceTypeId:
      state.countryServiceType && state.countryServiceType.serviceType.id,
    page: state.page,
    ...dogsFilterValueToQueryParams(state.dogsFilterValue),
    ...state.generalFiltersValue,
    sortBy: state.sortBy
  };

  // delete queryParams.locale;
  Object.keys(queryParams).forEach(key =>
    queryParams[key] === undefined || queryParams[key] === null
      ? delete queryParams[key]
      : ""
  );

  return queryParams;
}

function queryParamsToApiInputVariables(query: QueryType) {
  const { dogs, page, ...rest } = query;

  // Cast query.page from string to integer, or default to 1
  const queryVariables: any = {
    ...rest,
    page: +page || 1,
    includePrice: !!rest.serviceTypeId
  };

  // Parse dogs from a JSON string into an object
  if (dogs) {
    const dogsObj = JSON.parse(dogs);
    queryVariables.dogs = dogsObj;
  }

  // Cast boolean flags from string to bool
  ["withDogs", "withOutsideArea", "withoutKids"].map(key => {
    if (query[key] !== undefined) {
      queryVariables[key] = JSON.parse(query[key]);
    }
  });

  return queryVariables;
}

function searchReducer(state: State, action: Action) {
  switch (action.type) {
    case "changeDatesFilterValue":
    case "changeDogsFilterValue":
    case "changeGeneralFiltersValue":
    case "changeCountryServiceType":
    case "changeSortBy":
      return { ...state, ...action.payload, page: null };
    case "changePlace":
      return { ...state, ...action.payload, page: null, coordinates: null };
    case "changeCoordinates":
      return { ...state, ...action.payload, page: null, place: undefined };
    case "clearCountryServiceType":
      return {
        ...state,
        countryServiceType: undefined
      };
    case "clearPlace":
      return {
        ...state,
        place: undefined
      };
    case "clearDates":
      return {
        ...state,
        datesFilterValue: undefined
      };
    case "reset":
      return { ...intialSearchState };
    default:
      throw new Error();
  }
}

function SearchProvider({
  initialStateFromQuery,
  children
}: SearchProviderProps) {
  const [state, dispatch] = useReducer(searchReducer, initialStateFromQuery);

  return (
    <SearchStateContext.Provider value={state}>
      <SearchDispatchContext.Provider value={dispatch}>
        {children}
      </SearchDispatchContext.Provider>
    </SearchStateContext.Provider>
  );
}

SearchProvider.defaultProps = {
  initialStateFromQuery: {}
};

function useSearchState() {
  const context = useContext(SearchStateContext);

  if (context === undefined) {
    throw new Error("useSearchState must be used within a SearchProvider");
  }

  return context;
}

function useSearchDispatch() {
  const context = useContext(SearchDispatchContext);
  if (context === undefined) {
    throw new Error("useSearchDispatch must be used within a SearchProvider");
  }
  return context;
}

export {
  SearchProvider,
  useSearchState,
  useSearchDispatch,
  getInitialStateFromQuery,
  stateToQueryParams,
  queryParamsToApiInputVariables
};
