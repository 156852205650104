const styles = {
  testimonials: {
    container: {
      flexWrap: ["nowrap", "nowrap", "wrap"],
      flexDirection: ["column", "column", "row"],
      justifyContent: "center",
      alignItems: ["center", "center", "flex-start"]
    },
    numbersGridBox: {
      borderRadius: "6px",
      borderColor: "inuOrange",
      overflow: "hidden"
    }
  },
  reviewBox: {
    container: {
      flexDirection: "column",
      width: "280px",
      alignItems: "center",
      textAlign: "center",
      backgroundColor: "samoyedWhite",
      border: "2px solid",
      borderColor: "inuOrange",
      borderRadius: "6px",
      padding: "16px"
    },
    imageBox: {
      ".image": { borderRadius: "50%" }
    },
    image: {
      width: "100px",
      height: "100px",
      layout: "fill",
      objectFit: "cover"
    },
    title: {
      fontSize: "16px",
      lineHeight: "20px",
      fontWeight: "600",
      my: 1
    },
    textBox: {
      pb: 2,
      borderBottom: "1px solid",
      borderColor: "inuOrange"
    },
    text: {
      fontSize: "16px",
      lineHeight: "20px",
      fontWeight: "400"
    }
  },
  cardTitle: {
    color: "samoyedWhite",
    mb: 3,
    fontSize: "32px",
    lineHeight: "32px",
    fontWeight: 700
  },
  benefits: {
    container: {
      width: "280px",
      border: "2px solid",
      borderColor: "inuOrange",
      borderRadius: "6px",
      backgroundColor: "inuOrange",
      padding: "16px"
    },
    row: {
      mb: "12px",
      alignItems: "center"
    },
    icon: {
      width: "50px",
      height: "50px",
      border: "1px solid",
      borderColor: "samoyedWhite",
      borderRadius: "16px",
      p: "12px",
      mr: "12px"
    },
    text: {
      color: "samoyedWhite"
    },
    link: {
      color: "samoyedWhite",
      textDecoration: "underline"
    }
  },
  reviewLink: {
    container: {
      width: "280px",
      flexDirection: "column",
      backgroundColor: "inuOrange",
      border: "1px solid",
      borderColor: "inuOrange",
      borderRadius: "6px",
      p: 2,
      color: "samoyedWhite"
    },
    image: {
      width: "250px",
      height: "328px",
      my: 3
    },
    button: {
      fontSize: "24px",
      lineHeight: "30px",
      fontWeight: 700
    }
  }
};

export default styles;
