import { Box } from "rebass/styled-components";
import Image from "next/image";

interface Props {
  src: string; // The path of the file without the size suffix
  alt?: string;
  sizes?: number[]; // An array of sizes in case they're not the default ones
  darken?: number; // A decimal value to add a black overlay
  shouldLazyLoad?: boolean;
  priority?: boolean;
}

// This is an image component to be used as CoverImage
// It supports responsive images using srcSet and uses
// objectFit: "cover" to avoid having to use background-image
const CoverImage = (props: Props) => {
  const { src, alt, darken, shouldLazyLoad, priority } = props;

  return (
    <Box
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "black",
        "&::after": darken &&
          darken > 0 && {
            content: "''",
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: "black",
            opacity: darken
          }
      }}
    >
      <Image
        src={src}
        alt={alt}
        loading={shouldLazyLoad ? "lazy" : "eager"}
        priority={priority}
        fill
        sizes="100vw"
        style={{
          objectFit: "cover"
        }} />
    </Box>
  );
};

CoverImage.defaultProps = {
  darken: 0,
  shouldLazyLoad: true,
  priority: false
};
export default CoverImage;
