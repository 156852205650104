import useTranslation from "next-translate/useTranslation";
import { Flex, Box, Text } from "rebass/styled-components";
import Button from "../common/Button";
import { useContext } from "react";
import { LocaleContext } from "../../components/locale";
import { useGetTopReviewsByCountryQuery } from "../../generated/graphql";
import { InferredReview, InferredReviewable } from "../../interfaces";
import ReviewBox from "../common/ReviewBox";
import { pushRoute } from "../../lib/routing";
import { reviewsStyles as styles } from "../../lib/styles/homeStyles";
import Hero from "../Hero";

function TestimonialsBlock() {
  const { t } = useTranslation("home");

  const { locale, country } = useContext(LocaleContext);
  const language = (locale ?? "en").substring(0, 2);
  const countryId =
    (country && process.env.countryId && process.env.countryId[country]) || "";
  const countryImg = country === "gb" ? "uk" : country;

  // Data
  const { data, loading } = useGetTopReviewsByCountryQuery({
    variables: { countryId }
  });

  // Image
  const imgSrc = `/images/home/${language}/map_${countryImg}.webp`;

  // Reviews
  const reviewList: InferredReview[] = data?.topReviewsByCountry || [];
  const shortReviewList = reviewList
    .filter(rev => rev.content?.length && rev.content?.length <= 250)
    .slice(0, 3);

  // Actions
  const submit = () => {
    pushRoute({ pathname: "/testimonials" });
  };

  return (
    <Box height={["inherit", "inherit", "565px"]} p={0}>
      <Hero variant="reviews" imgsrc={imgSrc} darken={0.5}>
        <Box>
          <Text as="h2" sx={styles.title}>
            {t("readReviews")}
          </Text>
          <Flex sx={styles.reviewsContainer}>
            {shortReviewList.map((_, index) => {
              const review: InferredReview = shortReviewList[index];
              const reviewable: InferredReviewable = review?.reviewable;
              if (
                review &&
                reviewable.__typename === "Caregiver" &&
                reviewable.avatar &&
                reviewable.avatar.circle
              ) {
                return (
                  <ReviewBox
                    key={index}
                    avatar={reviewable.avatar.circle}
                    name={reviewable.name!}
                    reviewText={review.content!}
                    publishedAt={review.createdAt!}
                    authorName={review.author.name!}
                    showFooter={false}
                  />
                );
              }
            })}
          </Flex>

          <Flex sx={{ justifyContent: "center" }}>
            <Button variant="primary" onClick={submit} sx={styles.button}>
              {t("readReviewsBtn")}
            </Button>
          </Flex>
        </Box>
      </Hero>
    </Box>
  );
}

export default TestimonialsBlock;
