export const numbersGridStyles = {
  default: {
    mainBox: {
      py: 4,
      px: [2, 2, "20%"]
    },
    title: {
      color: "inuOrange",
      mb: "36px",
      textAlign: ["left", "left", "center"],
      fontSize: ["30px", "30px", "32px"]
    },
    grid: {
      flexWrap: "wrap"
    },
    tile: {
      flexDirection: "column",
      textAlign: "center",
      px: 1,
      py: 2
    },
    tileBox: {
      width: ["100%", "100%", "60%"],
      mx: "auto"
    },
    tileTitle: {
      color: "inuOrange",
      fontSize: ["30px", "30px", "48px"],
      lineHeight: ["30px", "30px", "72px"],
      fontWeight: "medium",
      mb: ["4px", "4px", 0]
    },
    tileDesc: {
      fontWeight: "book"
    }
  },
  card: {
    mainBox: {
      width: "282px",
      py: 4,
      px: [2, 2, 2],
      backgroundColor: "inuOrange"
    },
    title: {
      color: "samoyedWhite",
      mb: 3,
      textAlign: "center",
      fontSize: ["30px", "30px", "32px"]
    },
    grid: {
      flexWrap: "wrap"
    },
    tile: {
      flexDirection: "column",
      textAlign: "center",
      py: "12px"
    },
    tileBox: {
      width: "100%",
      mx: "auto"
    },
    tileTitle: {
      color: "samoyedWhite",
      fontSize: ["30px", "30px", "25px"],
      lineHeight: ["30px", "30px", "32px"],
      fontWeight: 600,
      mb: ["4px", "4px", 0]
    },
    tileDesc: {
      color: "samoyedWhite",
      fontWeight: 500
    }
  },
  home: {
    mainBox: {
      variant: "variants.layout.semilarge",
      py: "40px"
    },
    title: {
      mb: 3,
      fontSize: ["30px", "30px", "32px"]
    },
    grid: {
      flexWrap: "wrap"
    },
    tile: {
      flexDirection: "column",
      textAlign: "center",
      px: 1,
      py: 2
    },
    tileBox: {
      width: ["100%", "100%", "60%"],
      mx: "auto"
    },
    tileTitle: {
      color: "inuOrange",
      fontSize: ["48px", "48px", "48px"],
      lineHeight: ["48px", "48px", "72px"],
      fontWeight: "medium",
      mb: ["4px", "4px", 0]
    },
    tileDesc: {
      fontWeight: "book"
    }
  }
};

export const optionsGridStyles = {
  mainBox: {
    px: [4, 4, "304px"],
    py: 4,
    backgroundColor: "aquamarine"
  },
  title: {
    color: "samoyedWhite",
    textAlign: "center",
    mb: 3
  },
  gridFlex: {
    flexDirection: ["column", "column", "row"],
    rowGap: [8, 8, 0],
    columnGap: [0, 0, 8],
    justifyContent: "center"
  },
  tile: {
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    width: ["100%", "100%", "384px"],
    minWidth: ["100%", "100%", "384px"]
  },
  tileText: {
    py: 2,
    width: "60%",
    color: "samoyedWhite",
    fontSize: "24px",
    lineHeight: "24px",
    fontWeight: ["regular", "regular", "medium"],
    textAlign: "center"
  },
  tileButton: {
    backgroundColor: "springGreen",
    borderRadius: "6px",
    color: "samoyedWhite",
    fontSize: "18px",
    lineHeight: "30px",
    fontWeight: "book",
    width: ["256px", "256px", "332px"]
  }
};
