import { useContext } from "react";
import { Flex, Box, Image, Text } from "rebass/styled-components";
import format from "date-fns/format";
import useTranslation from "next-translate/useTranslation";

import ReviewStars from "./ReviewStars";
import styles from "../../lib/styles/testimonials.js";
import { getDateFnsLocale } from "../../lib/utils";
import { LocaleContext } from "../locale";

interface Props {
  avatar: string;
  name: string;
  reviewText: string;
  fixedheight?: string;
  publishedAt?: string;
  authorName?: string;
  showFooter?: boolean;
}

function ReviewBox(props: Props) {
  const { t } = useTranslation("testimonials");

  const {
    avatar,
    name,
    reviewText,
    publishedAt,
    authorName,
    fixedheight,
    showFooter = true
  } = props;

  const { locale } = useContext(LocaleContext);
  const dateLocale = getDateFnsLocale(locale);

  return (
    <Flex sx={styles.reviewBox.container} height={fixedheight}>
      <Box sx={styles.reviewBox.imageBox}>
        <Image src={avatar} alt="avatar" className="image" />
      </Box>

      <Text sx={styles.reviewBox.title}>{name}</Text>

      <ReviewStars sx={{ mb: 1 }} />

      <Box
        mb={2}
        sx={
          (!!authorName || !!publishedAt) &&
          showFooter &&
          styles.reviewBox.textBox
        }
      >
        <Text sx={styles.reviewBox.text}>{reviewText}</Text>
      </Box>

      {(!!authorName || !!publishedAt) && showFooter && (
        <Box>
          {!!authorName && (
            <Text>
              {t("reviewedBy")} {authorName}
            </Text>
          )}
          {!!publishedAt && (
            <Text mt="2px" sx={{ ...styles.reviewBox.text }}>
              {format(new Date(publishedAt), "dd MMM yyyy", {
                locale: dateLocale
              })}
            </Text>
          )}
        </Box>
      )}
    </Flex>
  );
}

export default ReviewBox;
