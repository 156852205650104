import { Flex, Text } from "rebass/styled-components";
import ServiceIcon from "../common/ServiceIcon";
import { searchStyles as styles } from "../../lib/styles/homeStyles";
import { CountryServiceType } from "../../interfaces";

interface Props {
  service: CountryServiceType;
  selected: boolean;
  onSelect: () => void;
  isMobile: boolean;
}

function ServiceTile(props: Props) {
  const { service, selected, onSelect, isMobile } = props;

  const containerStyle = {
    ...(isMobile ? styles.serviceBlockMobile : styles.serviceBlockWeb),
    ...(selected && styles.serviceBlockSelected)
  };

  const iconViewStyle = {
    ...styles.serviceIconView,
    ...(isMobile && { border: 0 })
  };
  const titleStyle = {
    ...styles.serviceTitle,
    ...(selected && { color: "white" })
  };
  const descStyle = {
    ...styles.serviceDesc,
    ...(selected && { color: "white" })
  };

  return (
    <Flex
      sx={{ ...styles.serviceBlock, ...containerStyle }}
      onClick={() => onSelect()}
    >
      <Flex sx={iconViewStyle}>
        <ServiceIcon
          color={selected ? "samoyedWhite" : "darkGray"}
          serviceTypeId={+(service?.serviceType.id || "1")}
          fontSize={10}
        />
      </Flex>

      <Flex sx={styles.textBlock}>
        <Text
          as="p"
          variant="highlight"
          sx={{ fontSize: "16px", lineHeight: 1.5 }}
          style={titleStyle}
        >
          {service?.seoName}
        </Text>
        <Text
          as="p"
          style={{
            ...descStyle,
            ...{ textAlign: isMobile ? "left" : "center" }
          }}
        >
          {service?.description}
        </Text>
      </Flex>
    </Flex>
  );
}

export default ServiceTile;
