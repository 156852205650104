import useTranslation from "next-translate/useTranslation";
import NumbersGrid, { NumbersTileProps } from "../common/NumbersGrid";

interface Props {
  mobile: boolean;
}

function HomeNumbersGrid({ mobile }: Props) {
  const { t } = useTranslation("home");

  const itemsNumbers: NumbersTileProps[] = Array.from(
    { length: 6 },
    (_, i) => ({
      title: t(`numbersTileTitle${i + 1}`),
      desc: t(`numbersTileDesc${i + 1}`)
    })
  );

  return (
    <NumbersGrid
      title={t("numbersTitle")}
      items={itemsNumbers}
      numCols={mobile ? 1 : 3}
      variant={"home"}
    />
  );
}

export default HomeNumbersGrid;
