import { Flex, Text, Box } from "rebass/styled-components";
import { searchStyles as styles } from "../../lib/styles/homeStyles";
import Button from "../common/Button";
import Heading from "../common/Heading";
import Rating from "../common/Rating";
import useTranslation from "next-translate/useTranslation";
import ServiceTile from "./ServiceTile";
import {
  CountryServiceType,
  InferredCountryServiceType
} from "../../interfaces";
import { useEffect } from "react";
import {
  stateToQueryParams,
  useSearchDispatch,
  useSearchState
} from "../search/SearchContext";
import { pushRoute } from "../../lib/routing";

interface Props {
  showBanner: boolean;
  countryServiceTypes: InferredCountryServiceType[];
  isMobileViewport: boolean;
}

function SearchBlock(props: Props) {
  const { showBanner, countryServiceTypes, isMobileViewport } = props;

  const { t } = useTranslation("home");
  const searchState = useSearchState();
  const searchDispatch = useSearchDispatch();

  const maxContainerWidth =
    countryServiceTypes.length > 3
      ? ["100%", "100%", "90%", "70%", "65%"]
      : ["100%", "100%", "50%", "50%", "60%"];

  const onSelectService = (countryServiceType: CountryServiceType) => {
    searchDispatch({
      type: "changeCountryServiceType",
      payload: { countryServiceType }
    });
  };

  const onSubmit = () => {
    pushRoute({ pathname: "/search", search: stateToQueryParams(searchState) });
  };

  useEffect(() => {
    if (!searchState.countryServiceType) {
      onSelectService(countryServiceTypes[0]);
    }
  }, []);

  return (
    <Flex sx={styles.bannerFlex}>
      <Flex
        sx={{
          ...styles.searchBox,
          maxWidth: maxContainerWidth,
          ...(showBanner && styles.searchBoxWithBanner)
        }}
      >
        <Box>
          <Heading as="h1" variant="h2" color="labradorBlack">
            {t("home:mobileAppTitle2")}
          </Heading>

          {isMobileViewport && (
            <Text as="h5" sx={styles.annotation}>
              {t("bestAlternativeToKennels")}
            </Text>
          )}
        </Box>

        <Box>
          <Text variant="h4_highlight" as="h2" sx={styles.subTitle}>
            {t("home:whichService")}
          </Text>

          <Flex sx={styles.servicesContainer}>
            {countryServiceTypes?.map((service, i) => (
              <ServiceTile
                key={i}
                service={service}
                selected={service.id === searchState.countryServiceType?.id}
                onSelect={() => onSelectService(service)}
                isMobile={isMobileViewport}
              />
            ))}
          </Flex>
        </Box>

        <Button variant="primary" size="large" onClick={() => onSubmit()}>
          {t("search")}
        </Button>

        <Flex sx={styles.reviewsblock}>
          <Rating score={5} pt={0}></Rating>
          <Text as="p" variant="h4_highlight">
            {t("reviewStars")}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default SearchBlock;
