import { Image, Flex, Box, Text } from "rebass/styled-components";
import Icon from "../common/Icon";
import useTranslation from "next-translate/useTranslation";
import { useContext } from "react";
import { LocaleContext } from "../locale";
import { mobileAppStyles as styles } from "../../lib/styles/homeStyles";

interface Props {
  mobile: boolean;
}

function MobileAppFeatures({ mobile }: Props) {
  const { t } = useTranslation("home");
  const { locale, country } = useContext(LocaleContext);
  const language = (locale ?? "en").substring(0, 2);

  const icons = ["download", "search", "support", "photo"];
  const infoList = [...Array(icons.length)].map((_, i) => {
    return {
      iconName: icons[i],
      title: t(`mobileAppTitle${i + 1}`),
      desc: t(`mobileAppDesc${i + 1}`)
    };
  });

  return (
    <Flex variant="layout.semilarge" sx={styles.mainContainer}>
      <Flex sx={styles.mobileImgContainer}>
        <Box>
          <Image
            alt="Gudog App demo 1"
            src={`/images/home/${language}/mobile_1.webp`}
            layout="fill"
            sx={styles.img}
          />
        </Box>
      </Flex>

      <Flex width={["100%", "100%", "70%"]} sx={styles.infoContainer}>
        <Text as="h2" sx={styles.mainTitle}>
          {t("gudogOnYourPhoneHeading")}
        </Text>

        {infoList.map((info, index) => (
          <Flex key={index} sx={{ gap: "16px" }}>
            <Flex sx={styles.iconContainer}>
              <Icon name={info.iconName} color="inuOrange" fontSize={6} />
            </Flex>
            <Flex sx={styles.textContainer}>
              <Text as="h3">{info.title}</Text>
              <Text as="p">{info.desc}</Text>
            </Flex>
          </Flex>
        ))}

        <Flex sx={styles.downloadContainer}>
          <Flex sx={{ gap: "32px" }}>
            {!mobile && (
              <Image
                alt="Gudog App QR code"
                padding={1}
                backgroundColor="samoyedWhite"
                src="/images/home/mobile-qr.png"
                width={"auto"}
                height={"132px"}
              />
            )}

            <Flex sx={{ flexDirection: "column", gap: "16px" }}>
              <Box>
                <a
                  href="https://play.google.com/store/apps/details?id=com.gudog.app"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <Image
                    alt="Gudog - Google Play"
                    src={`/images/home/${language}/google_play.svg`}
                    width={["100%", "100%", "100%", "auto"]}
                    height={["70px", "70px", "70px", "55px"]}
                  />
                </a>
              </Box>
              <Box>
                <a
                  href={`https://apps.apple.com/${country}/app/gudog/id964470204`}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <Image
                    alt="Gudog - AppStore"
                    src={`/images/home/${language}/app_store.svg`}
                    width={["100%", "100%", "100%", "100%"]}
                    height={["70px", "70px", "70px", "55px"]}
                  />
                </a>
              </Box>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default MobileAppFeatures;
